<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
      </b-row>
      <account-plan-submenu active="assets"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-assets-header></account-plan-assets-header>

        <b-card no-body class="white-tabs-card">

          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <AssetsMenu>
              <template slot="account-plan-assets-categorization">
                <div>
                  <b-row no-gutters>
                    <b-col cols="8">
                      <div class="text-regular" v-html="$t(translationPath + 'asset.categorization.description')"></div>
                    </b-col>
                  </b-row>
                  <b-table class="spirecta-simple-table account-plan-table income-accounts-table" show-empty hover
                          responsive striped
                          stacked="md"
                          :items="accountsGrouped.asset"
                          :fields="fieldValues"
                          :tbodyTrClass="trClass"
                          :busy="busy"
                  >
                    <template v-slot:table-busy>
                      <loader/>
                    </template>

                    <template v-slot:cell(title)="row">
                      <template v-if="row.item && row.item.is_account === true">
                        <span class="px-2 account_title">&ndash;</span>
                        <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'" class="account_title">
                          {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                        </b-link>
                        <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help"
                                :title="$t(translationPath+'badge_suspended_help')">
                          {{ $t( translationPath + 'badge_suspended') }}
                        </b-badge>
                      </template>
                      <template v-else-if="row.item">
                        <b-link :to="'/reports/performance/account-groups/'+row.item.id" class="account_group_title">
                          {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                        </b-link>
                      </template>
                      <template v-else>&nbsp;</template>
                    </template>

                    <template v-slot:cell(subtype)="row">
                      <template v-if="row.item && row.item.is_account">
                        <b-form-select
                          :disabled="isFormDisabled"
                          v-model="row.item.subtype"
                          :options="subtypeOptions"
                          @input="onChange"
                        ></b-form-select>
                      </template>
                      <template v-else-if="row.item && !row.item.is_account">
                        <div class="tr_heading">{{row.field.label}}</div>
                      </template>
                      <template v-else>&nbsp;</template>
                    </template>

                    <template v-slot:cell(is_productive_asset)="row">
                      <template v-if="row.item.is_account_group === true">
                        <div class="tr_heading">{{ row.field.label }}</div>
                      </template>
                      <template v-else-if="row.item.is_account === true">
                        <b-form-select
                          :disabled="isFormDisabled"
                          v-model="row.item.is_productive_asset"
                          :options="yesNoOptions"
                          @input="onChange"
                        ></b-form-select>
                      </template>
                    </template>

                    <template v-slot:cell(is_bucket_free_capital)="row">
                      <template v-if="row.item.is_account">
                        <b-form-select
                          :disabled="isFormDisabled"
                          v-model="row.item.is_bucket_free_capital"
                          :options="freeTiedCapitalOptions"
                          @input="onChange"
                        ></b-form-select>
                      </template>
                      <template v-else-if="!row.item.is_account && row.item.type === 'asset'">
                        <div class="tr_heading">{{ row.field.label }}</div>
                      </template>
                      <template v-else>&nbsp;</template>
                    </template>
                    <template v-slot:cell(asset_risk_scale)="row">
                      <template v-if="row.item.is_account">
                        <b-form-select
                          :disabled="isFormDisabled"
                          v-model="row.item.asset_risk_scale"
                          :options="riskScaleOptions"
                          @input="onChange"
                        ></b-form-select>
                      </template>
                      <template v-else-if="!row.item.is_account && row.item.type === 'asset'">
                        <div class="tr_heading">{{ row.field.label }}</div>
                      </template>
                      <template v-else>&nbsp;</template>
                    </template>
                  </b-table>

                  <b-row class="mb-5 mt-4">
                    <b-col class="pt-4 pt-md-1">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="float-right ml-3 px-4 btn-save"
                        :disabled="saveDisabled"
                        @click="onSave"
                      >{{ $t('common.save') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </AssetsMenu>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AssetLiabilitiesSubtypes from '@/mixins/assetLiabilitiesSubtypes'
import AssetsMenu from './submenu/AssetsMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'

export default {
  name: 'AccountPlanAssetsCategorization',
  mixins: [AccountPlanTabMixin, AssetLiabilitiesSubtypes],
  components: { AssetsMenu, AccountPlanAssetsHeader },
  data () {
    return {
      accountsFilter: ''
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'subtype', label: this.$t(this.translationPath + 'subtype_save') },
        { key: 'is_productive_asset', label: this.$t(this.translationPath + 'is_productive_asset') },
        { key: 'is_bucket_free_capital', label: this.$t('assets_liabilities.create_account_wizard.assets.step3.is_bucket_free_capital') },
        { key: 'asset_risk_scale', label: this.$t(this.translationPath + 'asset_risk_scale') }
      ]
    },
    subtypeOptions () {
      return this.getAssetSubtypesArray()
    },
    riskScaleOptions () {
      const opts = [{ value: null, text: '' }]
      for (let n = 1; n <= 7; n++) {
        opts.push({
          value: n,
          text: this.$t('common.asset_liabilities.risk_scale.' + n)
        })
      }
      return opts
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('asset'),
        this.loadAccountGroups('asset')
      ])
        .then(() => {
          this.busy = false
        })
    },
    onSave () {
      this.saveDisabled = true

      const accounts = this.accountsGrouped.asset.filter((item) => item.is_account ? item : null)
      const putData = {
        accounts: []
      }
      accounts.map((item) => {
        putData.accounts.push({
          id: item.id,
          subtype: item.subtype,
          is_productive_asset: item.is_productive_asset,
          is_bucket_free_capital: item.is_bucket_free_capital,
          asset_risk_scale: item.asset_risk_scale
        })
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_asset_subtypes_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_asset_subtypes_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_asset_subtypes_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_asset_subtypes_title_failed').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
